import { Logger } from "./Logger";
import { isNumber, isWorker, roundToDecimal } from "./utils";

const REPORTING_THRESHOLD_MS = 100;
const LOAF_REPORT_COUNT = 4;

export class LoafObserver {
  public loafEntries: CustomPerformanceLongAnimationFrameTiming[] = [];
  private hasSent: boolean = false;
  public loafObserver: PerformanceObserver;

  constructor() {

    if (isWorker()) {
      return;
    }

    try {

      if (!self.PerformanceObserver ||
        !PerformanceObserver.supportedEntryTypes ||
        !PerformanceObserver.supportedEntryTypes.includes('long-animation-frame') ||
        !Array.prototype.flatMap) {
        return;
      }

      this.loafObserver = new PerformanceObserver((list) => {
        for (const entry of list.getEntries() as CustomPerformanceLongAnimationFrameTiming[]) {
          if (entry.duration > REPORTING_THRESHOLD_MS && entry.scripts.length > 0) {
            this.loafEntries.push(entry);
          }
        }
      });
      this.loafObserver.observe({ type: 'long-animation-frame', buffered: true });
    }
    catch (e) {
      Logger.error(e, "loaf ctor");
    }

  }

  getLoafs(): RM.LoafEntry[] {
    if (this.hasSent) {
      return [];
    }
    if (!this.loafObserver) {
      return [];
    }

    try {
      let loafsToSend = this.loafEntries
        .sort((a, b) => b.duration - a.duration)
        .slice(0, LOAF_REPORT_COUNT);

      return loafsToSend.flatMap((loaf) => {
        return loaf.scripts.map((script) => {
          return {
            ld: isNumber(loaf.duration) ? roundToDecimal(loaf.duration) : null,
            lst: isNumber(loaf.startTime) ? roundToDecimal(loaf.startTime) : null,
            lbd: isNumber(loaf.blockingDuration) ? roundToDecimal(loaf.blockingDuration) : null,
            ii: (loaf.firstUIEventTimestamp > 0),
            sd: isNumber(script.duration) ? roundToDecimal(script.duration) : null,
            su: script.sourceURL,
            sf: script.sourceFunctionName,
            sc: script.sourceCharPosition,
            sst: isNumber(script.startTime) ? roundToDecimal(script.startTime) : null,
            si: script.invoker,
            sit: script.invokerType,
            slsd: isNumber(script.forcedStyleAndLayoutDuration) ? roundToDecimal(script.forcedStyleAndLayoutDuration) : null,
            spd: isNumber(script.pauseDuration) ? roundToDecimal(script.pauseDuration) : null,
          }
        });
      });
    }
    catch (e) {
      Logger.error(e, "getLoaf");
      return [];
    }
  }

  sentLoafs() {
    this.hasSent = true;
    this.loafObserver?.disconnect();
    this.loafEntries.length = 0;
  }
}


//** Custom type overrides where not correctly defined in @types/dom or web-vitals.js **/

/**
 * The PerformanceLongAnimationFrameTiming interface is specified in the Long Animation Frames API and provides metrics on long animation frames (LoAFs) that occupy rendering and block other tasks from being executed.
 *
 * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/API/PerformanceLongAnimationFrameTiming)
 */
export interface CustomPerformanceLongAnimationFrameTiming extends PerformanceEntry {
  readonly blockingDuration: DOMHighResTimeStamp;
  readonly firstUIEventTimestamp: DOMHighResTimeStamp;
  readonly renderStart: DOMHighResTimeStamp;
  readonly scripts: CustomPerformanceScriptTiming[];
  readonly styleAndLayoutStart: DOMHighResTimeStamp;
}

/**
 * The PerformanceScriptTiming interface is specified in the Long Animation Frames API and provides metrics on individual scripts that contribute to long animation frames (LoAFs).
 *
 * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/API/PerformanceScriptTiming)
 */
export interface CustomPerformanceScriptTiming extends PerformanceEntry {
  readonly executionStart: DOMHighResTimeStamp;
  readonly forcedStyleAndLayoutDuration: DOMHighResTimeStamp;
  readonly invoker: string;
  readonly invokerType: string;
  readonly pauseDuration: DOMHighResTimeStamp;
  readonly sourceCharPosition: number;
  readonly sourceFunctionName: string;
  readonly sourceURL: string;
  readonly window: Window;
  readonly windowAttribution: string;
}
