import { Logger } from "./Logger";

export function isWorker() {
  return (typeof document === "undefined");
}

export function isURLSupported(): boolean {
  return !!(self.URL && self.URL.prototype && ('hostname' in self.URL.prototype));
}

export function truncateUrl(url: string): string {
  url = url || "";
  if (url.indexOf("?") >= 0) {
    url = url.split("?")[0];
  }
  if (url.length >= 1000) {
    url = url.substr(0, 1000);
  }
  return url;
}

export function truncate(str: string, length: number) {
  str = '' + str; // Handle cases where we might get a stringable object rather than a string.
  if (str.length <= length) { return str; }
  var truncatedLength = str.length - length;
  return str.substr(0, length) + '...{' + truncatedLength + '}';
}

export function roundToDecimal(num: number, places: number = 0): number {
  return parseFloat(num.toFixed(places));
}

export function isFirstPartyUrl(url: string, pageUrl: string): boolean {
  var tls = getTopLevelSegment(pageUrl);
  if (!tls) {
    return false;
  }

  try {
    var hostname = new URL(url).hostname;
    if (!hostname) {
      return false;
    }
    return hostname.indexOf(tls) >= 0;
  }
  catch (e) {
    Logger.error(e, `Problem parsing first party url: ${url}`)
    return false;
  }
}

var reservedTLDs = ["com", "net", "gov", "edu", "org"];

export function getTopLevelSegment(pageUrl: string): string {
  try {
    if (!pageUrl || pageUrl.indexOf("http") !== 0) {
      return null;
    }
    var url = new URL(pageUrl);
    var hostname = url.hostname;
    if (!hostname) {
      return null;
    }

    var segments = hostname.split(".");

    // ignore last segment, should be .com or whatever cute tld they use
    var firstSegment = segments.pop();
    if (firstSegment === "localhost") {
      return firstSegment;
    }

    if (hostname === "127.0.0.1") {
      return hostname;
    }

    var lastSegment = segments.pop();

    // If it's something like co.uk or mn.us
    if (lastSegment.length === 2) {
      lastSegment = segments.pop();
    }

    // Something like com.au
    if (reservedTLDs.indexOf(lastSegment) >= 0) {
      lastSegment = segments.pop();
    }

    return `${lastSegment}.`;

  }
  catch (e) {
    Logger.error(e, `Page Url: ${pageUrl}`)
    return null;
  }

}

export function describeElement(el: HTMLElement): string {
  if (!el) { return null; }
  try {
    let outerHtml = el.outerHTML;
    return outerHtml.substring(0, outerHtml.indexOf(">") + 1);
  }
  catch (e) {
    Logger.error(e, "failed to describe element");
    return null;
  }
}

/**
  * patch
  * Monkeypatch a method
  *
  * @param {Object} obj The object containing the method.
  * @param {String} name The name of the method
  * @param {Function} func A function to monkeypatch into the method. Will
  *         be called with the original function as the parameter.
  */
export function patch(obj: any, name: string, func) {
  var original = obj[name] || noop;
  obj[name] = func(original);
}

export function noop() { }

export function newTimeId(): number {
  return Math.floor((Date.now() + Math.random()) * 1000);
}

/**
  * has
  * Examines an object if it contains a nested addressable property. for
  * example, if you want to check if window.chrome.app exists, you can
  * check with has(window, "chrome.app");
  */
export function has(obj: any, path: string): boolean {
  try {
    var segments = path.split('.');
    var root = obj;
    for (var idx = 0; idx < segments.length; idx++) {
      if (root[segments[idx]]) {
        root = root[segments[idx]];
      }
      else {
        return false;
      }
    }
    return true;
  }
  catch (e) {
    return false;
  }
}

export function trimMetadata(obj: { [key: string]: string }): { [key: string]: string } {
  obj = obj || {};
  Object.keys(obj).forEach((key, i) => {
    if (i >= 50) {
      delete obj[key];
    }
    else {
      obj[key] = truncate(obj[key], 100)
    }
  });

  return obj;
}

export function getTag(thing: any): string {
  return Object.prototype.toString.call(thing);
}

export function isElement(thing: any): boolean {
  return isObject(thing) && thing['nodeType'] === 1;
}

export function isFunction(thing: any): boolean {
  return !!(thing && typeof thing === 'function');
}

export function isNumber(thing: any): boolean {
  return typeof thing === 'number' ||
    (isObject(thing) && getTag(thing) === '[object Number]');
}

export function isObject(thing: any): boolean {
  return !!(thing && typeof thing === 'object');
}

export function isString(thing: any): boolean {
  return typeof thing === 'string' ||
    (!isArray(thing) && isObject(thing) && getTag(thing) === '[object String]');
}

export function isArray(thing: any): boolean {
  return getTag(thing) === '[object Array]';
}

export function isBoolean(thing: any): boolean {
  return typeof thing === 'boolean' ||
    (isObject(thing) && getTag(thing) === '[object Boolean]');
}

export function isError(thing: any): boolean {
  if (!isObject(thing)) { return false; }
  var tag = getTag(thing);
  return tag === '[object Error]' ||
    tag === '[object DOMException]' ||
    (isString(thing['name']) && isString(thing['message']));
}

export function serialize(thing: any): string {
  function serializeElement(el) {
    var htmlTagResult = '<' + el.tagName.toLowerCase();
    var attributes = el['attributes'] || [];
    for (var idx = 0; idx < attributes.length; idx++) {
      htmlTagResult += ' ' + attributes[idx].name + '="' + attributes[idx].value + '"';
    }
    return htmlTagResult + '>';
  }

  var STR_UNDEFINED = 'undefined';
  var STR_NAN = 'NaN';

  if (thing === '') { return 'Empty String'; }
  if (thing === undefined) { return STR_UNDEFINED; }
  if (isString(thing) || isNumber(thing) || isBoolean(thing) || isFunction(thing)) {
    return '' + thing;
  }
  if (isElement(thing)) {
    return serializeElement(thing);
  }
  if (typeof thing === 'symbol') {
    return Symbol.prototype.toString.call(thing);
  }

  var result;
  try {
    result = JSON.stringify(thing, function (key, value) {
      if (value === undefined) { return STR_UNDEFINED; }
      if (isNumber(value) && isNaN(value)) { return STR_NAN; }
      // NOTE [Todd Gardner] Errors do not serialize automatically do to some
      //      trickery on where the normal properties reside. So let's convert
      //      it into an object that can be serialized.
      if (isError(value)) {
        return {
          'name': value['name'],
          'message': value['message'],
          'stack': value['stack']
        };
      }
      if (isElement(value)) {
        return serializeElement(value);
      }
      return value;
    });
  }
  catch (e) {
    // NOTE [Todd Gardner] There were circular references inside of the thing
    //      so let's fallback to a simpler serialization, just the top-level
    //      keys on the thing, using only string coercion.
    var unserializableResult = '';
    for (var key in thing) {
      if (!thing.hasOwnProperty(key)) { continue; }
      try {
        unserializableResult += ',"' + key + '":"' + thing[key] + '"';
      }
      catch (e) { /* don't care */ }
    }
    result = unserializableResult ?
      '{' + unserializableResult.replace(',', '') + '}' :
      'Unserializable Object';
  }

  // NOTE [Todd Gardner] in order to correctly capture undefined and NaN,
  //      we wrote them out as strings. But they are not strings, so let's
  //      remove the quotes.
  return result
    .replace(/"undefined"/g, STR_UNDEFINED)
    .replace(/"NaN"/g, STR_NAN);
}

export function ensureUrlIsAbsolute(url: string | URL): string {
  if (!url) {
    return "";
  }
  url = url.toString();
  if (url.startsWith("http") || isWorker()) {
    return url;
  }
  try {
    var absoluteUrl = new URL(url, document.baseURI).toString();
    return absoluteUrl;
  }
  catch (err) {
    return url;
  }
}


export function trimPayload(payload: RM.PerformancePayload, payloadLength: number): RM.PerformancePayload {
  var goalPayloadSize = 60000;
  var resourceBaseSize = 220;
  var amountToShrink = payloadLength - goalPayloadSize;

  if (payloadLength <= goalPayloadSize) {
    return payload;
  }

  try {
    var shrunkSoFar = 0;
    for (var i = payload.pageResources.length - 1; i > 0; i--) {
      if (shrunkSoFar >= amountToShrink) {
        break;
      }

      shrunkSoFar += payload.pageResources[i].url.length + resourceBaseSize;
    }

    payload.pageResources = payload.pageResources.slice(0, i);

    return payload;
  }
  catch (e) {
    Logger.error(e, "trimPayload failed");
    return payload;
  }
}